import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "접촉 면회 중단 안내드립니다.",
  "subtitle": "코로나19 감염자 확대로 접촉 면회 중단",
  "date": "2022-07-22T00:00:00.000Z",
  "author": "관리자"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`요양병원 방역수칙 변경`}</h1>
    <h2>{`- 추진배경`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`7월 이후 지역사회 감염이 급증`}</strong>{`하고 있으며, `}<strong parentName="p">{`요양병원도 집단감염 및 선제검사 양성율 증가`}</strong>{` 등 방역상황 변화 되었습니다.
이에, 본원에서는 `}<strong parentName="p">{`감염요인 유입을 차단`}</strong>{`하고 `}<strong parentName="p">{`집단감염 발생을 방지`}</strong>{`하기 위해 `}<strong parentName="p">{`방역수칙 강화`}</strong>{`하게 되었습니다.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`기간 : 2022.07.25(월) ~ 별도 안내시까지`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`변경사항은 아래와 같습니다.`}</strong></p>
      </li>
    </ul>
    <h2>{`- 변경사항`}</h2>
    <p><strong parentName="p"><span style={{
          "color": "red"
        }}>{`👉필수 외래 진료 외 외출·외박 금지`}</span></strong>{`
`}<strong parentName="p"><u><span style={{
            "color": "red"
          }}>{`👉접촉면회는 비접촉 대면면회로 전환`}</span></u></strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`구 분`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`기 존`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`변 경(7.25~)`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`면회`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{` 접촉면회 허용`}</b>{` \\ - 방역수칙 준수`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`비접촉 대면 면회`}</b>{` \\ - 접촉면회 금지`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`외출·외박`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`허 용`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><b>{`필수 외래진료만 \\ 외출·외박 허용`}</b></td>
        </tr>
      </tbody>
    </table>
    <ul>
      <li parentName="ul">{`비접촉 대면 면회 전 방문자 명부작성, 체온측정, 손 소독 등에 협조 바랍니다.`}</li>
      <li parentName="ul">{`비접촉 대면 면회 도중 마스크를 벗거나 음식섭취는 불가합니다.`}</li>
    </ul>
    <h2>{`- 비접촉 대면 면회예약시 안내사항`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li"><span style={{
            "color": "red"
          }}>{`"비접촉 대면 면회사전예약 필수 및 면회시간 엄수"`}</span></strong></li>
      <li parentName="ul">{`예약전화 가능시간 : 오전10시~오후5시 📞`}<a parentName="li" {...{
          "href": "tel:010-2844-9989"
        }}>{`010-2844-9989`}</a>{`
(예약없이 방문할 경우 면회 불가)`}</li>
      <li parentName="ul">{`면회인원은 4인 이내로 가능하며, `}<strong parentName="li">{`추가인원은 면회 불가`}</strong></li>
      <li parentName="ul">{`면회시간 10분으로 제한합니다. `}<u>{`(13시 ~ 15시까지 진행)`}</u></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      